




import { Component, Vue } from "vue-property-decorator";
import Presidenti from "@/components/pages/presidenti/Presidenti.vue";

@Component({
  components: {
    Presidenti,
  },
})
export default class RootPresidentiView extends Vue {}
