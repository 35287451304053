



















































import { Component, Mixins, Prop } from "vue-property-decorator";
import { UtentiReturned, LogFilters, LogReturned } from "operatn-api-client";

import LogHandlerMixin from "@/mixins/handlers/LogHandlerMixin";
import UtenteHandlerMixin from "@/mixins/handlers/UtenteHandlerMixin";
import OperatnBaseTable, { Actions, Column, RowColors } from "@/components/gears/bases/OperatnBaseTable.vue";

import OperatnInfoDialog from "@/components/gears/dialogs/OperatnInfoDialog.vue";
import OperatnFabbricatoForm from "@/components/gears/forms/OperatnFabbricatoForm.vue";
import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import OperatnLogInfo from "@/components/gears/infos/OperatnLogInfo.vue";

@Component({
  components: {
    OperatnInfoDialog,
    OperatnBaseTable,
    OperatnFabbricatoForm,
    OperatnDateInput,
    OperatnLogInfo,
  },
})
export default class Log extends Mixins<LogHandlerMixin, UtenteHandlerMixin>(LogHandlerMixin, UtenteHandlerMixin) {
  /* DATA */

  private showDettagli = false;
  private selectedLog: LogReturned | null = null;

  private logs: LogReturned[] = [];
  private utenti: UtentiReturned[] = [];
  private methods: string[] = ["GET", "POST", "PUT", "PATCH", "DELETE"];
  private filters: LogFilters = {
    uid: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    method: undefined,
  };

  /* GETTERS AND SETTERS */

  get columns(): Column<LogReturned>[] {
    return [
      {
        text: "ID",
        value: "id",
        groupable: false,
        editable: false,
      },
      {
        text: "Ruolo",
        value: "ruoloUtente",
        groupable: false,
        editable: false,
      },
      {
        text: "Utente",
        value: "username",
        groupable: false,
        editable: false,
      },
      {
        text: "Method",
        value: "method",
        groupable: false,
        editable: false,
      },
      {
        text: "Path",
        value: "path",
        groupable: false,
        editable: false,
        itemTextHandler: (value: string) => value.split("?")[0],
      },
      {
        text: "Risposta",
        value: "statusCode",
        groupable: false,
        editable: false,
      },
      {
        text: "Timestamp",
        value: "timestamp",
        groupable: false,
        editable: false,
        sort: (x: Date, y: Date) => +x - +y,
        itemTextHandler: (value: Date) => (value ? `${value.toLocaleDateString()} - ${value.toLocaleTimeString()}` : "NO"),
      },
    ];
  }

  get actions(): Actions<LogReturned> {
    return {
      others: [
        {
          icon: "mdi-eye",
          color: "green",
          title: "Dettagli",
          action: (item) => {
            this.selectedLog = item;
            this.showDettagli = true;
          },
        },
      ],
    };
  }

  rowBackgrounds(item: LogReturned): RowColors {
    if (item.method === "DELETE") {
      return "soft-red";
    } else if (item.method === "PUT" || item.method === "PATCH") {
      return "soft-blue";
    } else if (item.method === "POST") {
      return "soft-green";
    } else if (item.method === "GET") {
      return "";
    }
    return "soft-orange";
  }

  async updateLogs(): Promise<void> {
    const f: LogFilters = {};
    if (this.filters.uid) f.uid = this.filters.uid;
    if (this.filters.dateFrom) f.dateFrom = this.filters.dateFrom;
    if (this.filters.dateTo) f.dateTo = this.filters.dateTo;
    if (this.filters.method) f.method = this.filters.method;
    this.logs = await this.getAllLogs(f);
  }

  /* LIFE CYCLE */
  async mounted() {
    await this.updateLogs();
    this.utenti = await this.getUtenti();
  }
}
