import { render, staticRenderFns } from "./TipiStanza.vue?vue&type=template&id=634cb7a2&"
import script from "./TipiStanza.vue?vue&type=script&lang=ts&"
export * from "./TipiStanza.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports