






























































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { LogReturned } from "operatn-api-client";

import CopyMixin from '@/mixins/CopyMixin';

@Component
export default class OperatnLogInfo extends Mixins(CopyMixin) {
  /* PROPS */

  @Prop({ type: Object, required: true })
  value!: LogReturned;


  /* METHODS */

  stringify(value: any): string {
    return JSON.stringify(value, null, 2);
  }
}
