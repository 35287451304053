






















































import { Component, Mixins, Prop } from "vue-property-decorator";
import { PresidentiReturned } from "operatn-api-client";

import PresidenteHandlerMixin from "@/mixins/handlers/PresidenteHandlerMixin";

import OperatnPersonaInfo from "@/components/gears/infos/persona/OperatnPersonaInfo.vue";
import OperatnPresidenteInfo from "@/components/gears/infos/persona/OperatnPresidenteInfo.vue";
import OperatnLuogoNascitaInfo from "@/components/gears/infos/persona/OperatnLuogoNascitaInfo.vue";
import OperatnResidenzaInfo from "@/components/gears/infos/persona/OperatnResidenzaInfo.vue";
import OperatnDomiciliInfo from "@/components/gears/infos/persona/OperatnDomiciliInfo.vue";

@Component({
  components: {
    OperatnPersonaInfo,
    OperatnPresidenteInfo,
    OperatnLuogoNascitaInfo,
    OperatnResidenzaInfo,
    OperatnDomiciliInfo
  },
})
export default class PresidenteId extends Mixins(PresidenteHandlerMixin) {
  /* PROPS */

  @Prop({ type: Number, required: true })
  id!: number;

  /* DATA */

  private presidente: PresidentiReturned | null = null;
  private detailsExpanded = 0;

  /* METHODS */

  async downloadFirma(item: PresidentiReturned): Promise<void> {
    await this.$stored.download(item.firma as string, `firma_presidente_${item.id}`);
  }

  /* LIFE CYCLE */

  async mounted() {
    this.presidente = await this.getPresidente(this.id, {
      persona: {
        luogoDiNascita: true,
        residenza: true,
        domicili: true,
      },
    });
  }
}
