









































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { FabbricatiReturned } from "operatn-api-client";
import clipboardCopy from "clipboard-copy";

import { ActionTypes } from "@/store";

@Component
export default class OperatnFabbricatoInfo extends Vue {
  /* PROPS */

  @Prop({ type: Object, required: true })
  value!: FabbricatiReturned;

  /* GETTERS */

  get tipoFabbricato(): string {
    return this.value.tipoFabbricato?.tipoFabbricato ?? "NON TROVATO";
  }

  get indirizzo(): string {
    const nCivico = this.value.nCivico ?? "";
    return `${this.value.indirizzo} ${nCivico}`;
  }

  /* METHODS */

  copyText(text: string): void {
    clipboardCopy(text);
    this.$store.dispatch(ActionTypes.SET_TOAST, { message: "Testo copiato negli appunti", color: "success" });
  }
}
