




import { Component, Vue } from "vue-property-decorator";
import ContabilitaInvia from "@/components/pages/contabilita/invia/ContabilitaInvia.vue";

@Component({
  components: {
    ContabilitaInvia,
  },
})
export default class RootContabilitaInviaView extends Vue {}
