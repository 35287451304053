

























































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { ContoRicavi, TipiOspiteCreateBody, TipiOspiteReplaceBody } from "operatn-api-client";

import ContoRicaviHandlerMixin from "@/mixins/handlers/ContoRicaviHandlerMixin";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnTipoOspiteForm extends Mixins(ContoRicaviHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<TipiOspiteCreateBody> | Partial<TipiOspiteReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ type: Array, default: () => [] })
  tipiOspiteSigle!: string[];

  @Prop({ type: Array, default: () => [] })
  tipiOspiteValues!: string[];

  /* DATA */

  private contiRicavi: ContoRicavi[] = [];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyValue(): Partial<TipiOspiteCreateBody> | Partial<TipiOspiteReplaceBody> {
    return {
      tipoOspite: undefined,
      sigla: undefined,
      idContoRicaviConsumi: undefined,
      idContoRicaviCanoni: undefined
    };
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  /* LIFE CYCLE */

  async mounted() {
    this.contiRicavi = await this.getContiRicavi();
  }
}
