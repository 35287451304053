




















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { PresidentiCreateBody, PresidentiUpdateBody } from "operatn-api-client";

import OperatnDateInput from '@/components/gears/inputs/OperatnDateInput.vue';
import OperatnImageInput from '@/components/gears/inputs/OperatnImageInput.vue';

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDateInput,
    OperatnImageInput
  },
})
export default class OperatnDatiPresidente extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<PresidentiCreateBody> | Partial<PresidentiUpdateBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ validator: v => v instanceof File || typeof v === 'string' || v === null, default: null })
  firma!: File | string | null;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get internalFirma() {
    return this.firma;
  }
  set internalFirma(value) {
    this.$emit("update:firma", value);
  }
}
