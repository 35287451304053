




import { Component, Vue } from "vue-property-decorator";
import ContabilitaEstrai from "@/components/pages/contabilita/estrai/ContabilitaEstrai.vue";

@Component({
  components: {
    ContabilitaEstrai,
  },
})
export default class RootContabilitaEstraiView extends Vue {}
