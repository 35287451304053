











































import { Component, Prop, Mixins } from "vue-property-decorator";
import { PresidentiReturned } from "operatn-api-client";

import CopyMixin from "@/mixins/CopyMixin";

@Component
export default class OperatnPresidenteInfo extends Mixins(CopyMixin) {
  /* PROPS */

  @Prop({ type: Object, required: true })
  presidente!: PresidentiReturned;

  /* GETTERS */

  get dataInizioMandato(): string {
    return this.presidente.dataInizioMandato.toLocaleDateString();
  }
  get dataFineMandato(): string {
    return this.presidente.dataFineMandato.toLocaleDateString();
  }
  get firmaPath(): string | null {
    return this.presidente.firma ? this.$stored.getPath(this.presidente.firma) : null;
  }

  /* METHODS */

  async downloadFirma(): Promise<void> {
    if (this.presidente.firma) {
      await this.$stored.download(this.presidente.firma, 'firma.png');
    }
  }
}
