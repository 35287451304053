














import { Component, Vue } from "vue-property-decorator";

@Component
export default class RootView extends Vue {
  /* GETTERS */

  get elevated(): boolean {
    return !this.$route.meta?.noElevation;
  }
}
