



















































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ContiRicaviCreateBody, ContiRicaviReplaceBody } from "operatn-api-client";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnContoRicaviForm extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<ContiRicaviCreateBody> | Partial<ContiRicaviReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ type: Array, default: () => [] })
  contiRicaviCodici!: string[];

  @Prop({ type: Array, default: () => [] })
  contiRicaviValues!: string[];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyValue(): Partial<ContiRicaviCreateBody> | Partial<ContiRicaviReplaceBody> {
    return {
      codice: undefined,
      prg: undefined,
      conto: undefined,
      descrizione: undefined
    };
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }
}
