




















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TipiStanzaCreateBody, TipiStanzaReplaceBody } from "operatn-api-client";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnTipoStanzaForm extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: TipiStanzaCreateBody | TipiStanzaReplaceBody;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ type: Array, default: () => [] })
  tipiStanzaValues!: string[];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyValue(): TipiStanzaCreateBody | TipiStanzaReplaceBody {
    return {
      tipoStanza: "",
    };
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }
}
