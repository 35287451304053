

































































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { PresidentiCreateBody, PresidentiUpdateBody } from "operatn-api-client";

import OperatnDatiPersona from './gears/OperatnDatiPersona.vue';
import OperatnDatiPresidente from './gears/OperatnDatiPresidente.vue';
import OperatnLuogoNascita from './gears/OperatnLuogoNascita.vue';
import OperatnResidenza from './gears/OperatnResidenza.vue';
import OperatnDomicili from './gears/OperatnDomicili.vue';

@Component({
  model: {
    prop: "value",
    event: "save",
  },
  components: {
    OperatnDatiPersona,
    OperatnDatiPresidente,
    OperatnLuogoNascita,
    OperatnResidenza,
    OperatnDomicili
  },
})
export default class OperatnPresidenteForm extends Vue {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<PresidentiCreateBody> | Partial<PresidentiUpdateBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ validator: v => v instanceof File || v === null, default: null })
  firma!: File | string | null;

  /* DATA */

  private detailsExpanded = [0, 1, 2, 3, 4];
  private datiPersonaValid = false; 
  private datiPresidenteValid = false; 
  private luogoNascitaValid = false;
  private residenzaValid = false;
  private domiciliValid = false;

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get internalFirma() {
    return this.firma;
  }
  set internalFirma(value) {
    this.$emit("update:firma", value);
  }

  get firmaValid() {
    return this.internalFirma !== null;
  }

  /* METHODS */

  getEmptyValue(): Partial<PresidentiCreateBody> | Partial<PresidentiUpdateBody> {
    return {
      nome: undefined,
      cognome: undefined,
      sesso: undefined,
      codiceFiscale: null,
      dataDiNascita: undefined,
      dataInizioMandato: undefined,
      dataFineMandato: undefined,
      luogoDiNascita: undefined,
      residenza: undefined,
      domicili: []
    };
  }

  computeFormValid(): boolean {
    return this.datiPersonaValid && this.datiPresidenteValid && this.luogoNascitaValid && this.residenzaValid && this.domiciliValid && this.firmaValid;
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.internalValue === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  @Watch("datiPersonaValid")
  watchDatiContrattoValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("datiPresidenteValid")
  watchQuietanzianteValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("luogoNascitaValid")
  watchTipoContrattoValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("residenzaValid")
  watchTariffaValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("domiciliValid")
  watchOspitiPostiLettoValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }
  @Watch("firmaValid")
  watchFirmaValid() {
    this.$emit("update:formValid", this.computeFormValid());
  }

  /* LIFE CYCLE */

  async mounted() {
    if (this.internalValue === null) {
      this.internalValue = this.getEmptyValue();
    }
  }
}
