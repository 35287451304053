



















































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { TariffeCreateBody, TariffeReplaceBody, TipiOspiteReturned, TipoFabbricato, TipoStanza, TipoTariffa } from "operatn-api-client";

import TipoFabbricatoHandlerMixin from "@/mixins/handlers/TipoFabbricatoHandlerMixin";
import TipoOspiteHandlerMixin from "@/mixins/handlers/TipoOspiteHandlerMixin";
import TipoTariffaHandlerMixin from "@/mixins/handlers/TipoTariffaHandlerMixin";
import TipoStanzaHandlerMixin from "@/mixins/handlers/TipoStanzaHandlerMixin";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnTariffaForm extends Mixins(TipoFabbricatoHandlerMixin, TipoOspiteHandlerMixin, TipoTariffaHandlerMixin, TipoStanzaHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<TariffeCreateBody> | Partial<TariffeReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* DATA */

  private tipiFabbricato: TipoFabbricato[] = [];
  private tipiStanza: TipoStanza[] = [];
  private tipiOspite: TipiOspiteReturned[] = [];
  private tipiTariffa: TipoTariffa[] = [];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  /* METHODS */

  getEmptyValue(): Partial<TariffeCreateBody> | Partial<TariffeReplaceBody> {
    return {
      idTipoOspite: undefined,
      idUtilizzoStanza: null,
      idTipoFabbricato: null,
      idTipoTariffa: undefined,
      prezzoConsumi: undefined,
      prezzoCanoni: undefined,
    };
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  /* LIFE CYCLE */

  async mounted() {
    this.tipiFabbricato = await this.getTipiFabbricato();
    this.tipiStanza = await this.getTipiStanza();
    this.tipiTariffa = await this.getTipiTariffa();
    this.tipiOspite = await this.getTipiOspite();
  }
}
