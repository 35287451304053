























































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { StanzeReturned } from "operatn-api-client";
import clipboardCopy from "clipboard-copy";

import { ActionTypes } from "@/store";

@Component
export default class OperatnStanzaInfo extends Vue {
  /* PROPS */

  @Prop({ type: Object, required: true })
  value!: StanzeReturned;

  /* GETTERS */

  get nomeFabbricato(): string {
    return this.value.fabbricato?.nome ?? "NON TROVATO";
  }

  get tipoStanza(): string {
    return this.value.tipoStanza?.tipoStanza ?? "NON TROVATO";
  }

  /* METHODS */

  copyText(text: string): void {
    clipboardCopy(text);
    this.$store.dispatch(ActionTypes.SET_TOAST, { message: "Testo copiato negli appunti", color: "success" });
  }
}
