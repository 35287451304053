





















































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { StanzeCreateBody, StanzeReplaceBody, TipoStanza } from "operatn-api-client";

import TipoStanzaHandlerMixin from "@/mixins/handlers/TipoStanzaHandlerMixin";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnStanzaForm extends Mixins(TipoStanzaHandlerMixin, TipoStanzaHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<StanzeCreateBody> | Partial<StanzeReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  /* DATA */

  private tipiStanza: TipoStanza[] = [];

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get piani() {
    return this.$store.state.piani;
  }

  /* METHODS */

  getEmptyValue(): Partial<StanzeCreateBody> | Partial<StanzeReplaceBody> {
    return {
      unitaImmobiliare: undefined,
      numeroStanza: undefined,
      piano: undefined,
      bagno: false,
      centroDiCosto: undefined,
      gestioneDiretta: false,
      handicap: false,
      note: undefined,
      idTipoStanza: undefined,
    };
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  /* LIFE CYCLE */

  async mounted() {
    this.tipiStanza = await this.getTipiStanza();
  }
}
