




import { Component, Prop, Vue } from "vue-property-decorator";
import ContrattoId from "@/components/pages/contratti/id/ContrattoId.vue";

@Component({
  components: {
    ContrattoId,
  },
})
export default class RootContrattiIdView extends Vue {
  /* PROPS */

  @Prop({ type: String, required: true })
  cid!: string;

}
