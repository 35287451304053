
import { Component, Vue } from "vue-property-decorator";
import { BadRequestError, InvalidBodyError, InvalidPathParamError, NotFoundError, PresidentiCreateBody, PresidentiIncludeParams, PresidentiUpdateBody, PresidentiReturned } from "operatn-api-client";

import { ActionTypes, AlertType } from "@/store";

@Component
export default class PresidenteHandlerMixin extends Vue {

  /* METHODS */

  async getPresidenti(params: PresidentiIncludeParams = {}, alertType = AlertType.ERROR_ALERT): Promise<PresidentiReturned[]> {
    try {
      return await this.$api.presidenti.getAll(params, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile caricare i presidenti`, alertType });
      }
      throw error;
    }
  }

  async getPresidente(id: number, params: PresidentiIncludeParams = {}, alertType = AlertType.ERROR_ALERT): Promise<PresidentiReturned> {
    try {
      return await this.$api.presidenti.get(id, params, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Presidente ${id} non trovato`, alertType });
      }
      throw error;
    }
  }

  async createPresidente(body: PresidentiCreateBody, alertType = AlertType.ERROR_ALERT): Promise<number> {
    try {
      const id = await this.$api.presidenti.create(body, { alertType });
      return id;
    } catch (error) {
      if (error) {
        if (error instanceof InvalidBodyError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Dati del presidente da creare non validi`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
        } else {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
        }
      }
      throw error;
    }
  }

  async updatePresidente(id: number, body: PresidentiUpdateBody, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      await this.$api.presidenti.update(id, {
        nome: body.nome,
        cognome: body.cognome,
        codiceFiscale: body.codiceFiscale,
        sesso: body.sesso,
        dataDiNascita: body.dataDiNascita,
        dataFineMandato: body.dataFineMandato,
        dataInizioMandato: body.dataInizioMandato,
        luogoDiNascita: body.luogoDiNascita,
        residenza: body.residenza,
        domicili: body.domicili
      }, { alertType });
    } catch (error) {
      if (error) {
        if (error instanceof InvalidPathParamError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Id presidente non valido`, alertType });
        } else if (error instanceof InvalidBodyError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Dati da aggiornare del presidente non validi`, alertType });
        } else if (error instanceof NotFoundError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Presidente non trovato`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida.`, alertType });
        }
      }
      throw error;
    }
  }

  async uploadPresidenteFirma(id: number, formData: FormData, alertType = AlertType.ERROR_ALERT): Promise<string> {
    try {
      return await this.$api.presidenti.uploadFirma(id, formData, { alertType });
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Presidente non trovato`, alertType });
      } else if (error instanceof BadRequestError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
      } else {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
      }
      throw error;
    }
  }

  async deletePresidenteFirma(id: number, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      return await this.$api.presidenti.deleteFirma(id, { alertType });
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Presidente non trovato`, alertType });
      } else if (error instanceof BadRequestError) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida`, alertType });
      } else {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore del server`, alertType });
      }
      throw error;
    }
  }

  async deletePresidente(id: number, alertType = AlertType.ERROR_ALERT): Promise<void> {
    try {
      await this.$api.presidenti.delete(id, { alertType });
    } catch (error) {
      if (error) {
        if (error instanceof InvalidPathParamError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Uid presidente non valido`, alertType });
        } else if (error instanceof NotFoundError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Presidente non trovato`, alertType });
        } else if (error instanceof BadRequestError) {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Richiesta non valida.`, alertType });
        } else {
          this.$store.dispatch(ActionTypes.ALERT, { message: `Errore generico`, alertType });
        }
      }
      throw error;
    }
  }

}