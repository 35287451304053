
import { Component, Vue } from "vue-property-decorator";
import { ContabilitaBollettaInfo, ContabilitaDirsInfo, ContabilitaPageParams, ContabilitaQueryParams, StatusContabilita } from "operatn-api-client";

import { ActionTypes, AlertType } from "@/store";

@Component
export default class ContabilitaHandlerMixin extends Vue {

  /* METHODS */

  async getContabilitaBollette(params: ContabilitaQueryParams & ContabilitaPageParams, alertType = AlertType.ERROR_ALERT): Promise<ContabilitaBollettaInfo[]> {
    try {
      return await this.$api.contabilita.getBollette(params, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore nel caricare bollette`, alertType });
      }
      throw error;
    }
  }

  async getContabilitaBolletteCount(params: ContabilitaQueryParams, alertType = AlertType.ERROR_ALERT): Promise<number> {
    try {
      return await this.$api.contabilita.countBollette(params, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore nel contare bollette`, alertType });
      }
      throw error;
    }
  }

  async getContabilitaBolletteToExtract(params: ContabilitaQueryParams & ContabilitaPageParams, alertType = AlertType.ERROR_ALERT): Promise<ContabilitaBollettaInfo[]> {
    try {
      return await this.$api.contabilita.getBolletteToExtract(params, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore nel caricare bollette`, alertType });
      }
      throw error;
    }
  }

  async getContabilitaBolletteToExtractCount(params: ContabilitaQueryParams, alertType = AlertType.ERROR_ALERT): Promise<number> {
    try {
      return await this.$api.contabilita.countBolletteToExtract(params, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore nel contare bollette`, alertType });
      }
      throw error;
    }
  }

  async getContabilitaCronology(alertType = AlertType.ERROR_ALERT): Promise<ContabilitaDirsInfo[]> {
    try {
      return await this.$api.contabilita.getCronology({ alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore nel caricare cronologia contabilità`, alertType });
      }
      throw error;
    }
  }

  async sendContabilitaBollette(params: ContabilitaQueryParams, uuid: string | undefined, alertType = AlertType.ERROR_ALERT): Promise<number[]> {
    try {
      return await this.$api.contabilita.sendBollette(params, uuid, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore nell'inviare bollette`, alertType });
      }
      throw error;
    }
  }

  async sendContabilitaBolletteByIds(body: number[], uuid: string | undefined, alertType = AlertType.ERROR_ALERT): Promise<number[]> {
    try {
      return await this.$api.contabilita.sendBolletteByIds(body, uuid, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore nell'inviare bollette`, alertType });
      }
      throw error;
    }
  }

  async stopContabilitaBollette(uuid: string | undefined, alertType = AlertType.ERRORS_QUEUE): Promise<void> {
    try {
      await this.$api.contabilita.stopBollette(uuid, { alertType });
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Errore nel fermare l'invio delle bollette`, alertType });
      }
      throw error;
    }
  }

  async getContabilitaStatus(uuid: string): Promise<StatusContabilita | null> {
    try {
      return await this.$api.contabilita.getStatus(uuid);
    } catch (error) {
      return null;
    }
  }

}