
import { Component, Vue } from "vue-property-decorator";
import { LogReturned, LogFilters } from "operatn-api-client";

import { ActionTypes, AlertType } from "@/store";

@Component
export default class LogHandlerMixin extends Vue {

  /* METHODS */
  async getAllLogs(filters: LogFilters = {}, alertType = AlertType.ERROR_ALERT): Promise<LogReturned[]> {
    try {
      const logs = await this.$api.log.getAll(filters);
      logs.forEach(log => { log.timestamp = new Date(log.timestamp); });
      return logs as LogReturned[];
    } catch (error) {
      if (error) {
        this.$store.dispatch(ActionTypes.ALERT, { message: `Impossibile caricare i log`, alertType });
      }
      throw error;
    }
  }

}