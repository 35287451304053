


























































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import { ContabilitaBollettaInfo, ContabilitaPageParams, ContabilitaQueryParams, TipoOspite } from "operatn-api-client";

import { ActionTypes, AlertType } from "@/store";
import { SheetsHeaders, createTsvBlob, createXlsxBlob, downloadBlob } from "@/utils";
import { RowColors } from "@/components/gears/bases/OperatnBaseTable.vue";

import TipoOspiteHandlerMixin from "@/mixins/handlers/TipoOspiteHandlerMixin";
import ContabilitaHandlerMixin from "@/mixins/handlers/ContabilitaHandlerMixin";

import OperatnDateInput from "@/components/gears/inputs/OperatnDateInput.vue";
import OperatnOspiteInput from "@/components/gears/inputs/OperatnOspiteInput.vue";

@Component({
  components: {
    OperatnDateInput,
    OperatnOspiteInput,
  },
})
export default class ContabilitaEstrai extends Mixins(TipoOspiteHandlerMixin, ContabilitaHandlerMixin) {
  /* DATA */

  private formValid = false;
  private formValue = this.getEmptyBody();
  private loading = false;

  private tipiOspite: { id: number; label: string }[] = [];
  private loadingTipiOspite = false;

  private bollette: ContabilitaBollettaInfo[] = [];
  private totalBollette = 0;
  private loadingBollette = false;
  private headers: DataTableHeader[] = [
    {
      text: "ID Persona",
      value: "ospite.id",
    },

    {
      text: "Cognome",
      value: "ospite.persona.cognome",
    },
    {
      text: "Nome",
      value: "ospite.persona.nome",
    },
    {
      text: "Codice fiscale",
      value: "ospite.persona.codiceFiscale",
    },
    {
      text: "Quietanziante",
      value: "quietanziante.quietanziante",
    },
    {
      text: "ID Bolletta",
      value: "id",
    },
    {
      text: "Causale",
      value: "siglaCausale",
    },
    {
      text: "Tipo bolletta",
      value: "tipoBolletta.tipoBolletta",
    },
    {
      text: "Canoni",
      value: "importoCanoni",
    },
    {
      text: "Consumi",
      value: "importoConsumi",
    },
    {
      text: "Totale",
      value: "importoTotale",
    },
    {
      text: "Competenza dal",
      value: "competenzaDal",
    },
    {
      text: "Competenza al",
      value: "competenzaAl",
    },
    {
      text: "Scadenza",
      value: "dataScadenza",
    },
    {
      text: "Tipo ospite",
      value: "contratto.tariffa.tipoOspite.sigla",
    },
    {
      text: "Tipo contratto",
      value: "contratto.tipoContratto.sigla",
    },
    {
      text: "Centro di costo",
      value: "centroDiCosto",
    },
    {
      text: "Fabbricato",
      value: "fabbricato",
    },
    {
      text: "Stanza",
      value: "stanza",
    },
  ];
  private tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 15,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };

  /* GETTERS */

  get isDisabled(): boolean {
    return !this.formValid || this.loading;
  }
  get isDisabledExport(): boolean {
    return this.isDisabled || !this.bollette.length;
  }

  get handledBollette() {
    return this.bollette.map((b) => this.handleBolletta(b));
  }

  get queryParams(): ContabilitaQueryParams & ContabilitaPageParams {
    return {
      startDate: this.formValue.competenzaDal as Date,
      endDate: this.formValue.competenzaAl as Date,
      idTipoOspite: this.formValue.idTipoOspite ?? undefined,
      idOspite: this.formValue.idOspite ?? undefined,
      siglaCausale: this.formValue.siglaCausale ?? undefined,
      page: this.tableOptions.itemsPerPage > 0 ? this.tableOptions.page : undefined,
      pageSize: this.tableOptions.itemsPerPage > 0 ? this.tableOptions.itemsPerPage : undefined,
    };
  }

  get exportHeaders(): SheetsHeaders {
    const headers = this.headers.reduce((result, header) => ({ ...result, [header.value]: header.text }), {});
    return headers;
  }

  /* METHODS */

  getEmptyBody() {
    return {
      competenzaDal: null as Date | null,
      competenzaAl: null as Date | null,
      idOspite: null as number | null,
      idTipoOspite: null as number | null,
      siglaCausale: null as string | null,
    };
  }

  parseTipiOspite(tipiOspite: TipoOspite[]) {
    return tipiOspite.map((tipoOspite) => ({
      id: tipoOspite.id,
      label: `${tipoOspite.sigla} - ${tipoOspite.tipoOspite}`,
    }));
  }

  rowBackgrounds(item: ContabilitaBollettaInfo): RowColors {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    if (item.dataInvioEusis !== null) {
      if (item.dataIncasso !== null) {
        return "soft-green";
      } else if (item.dataScadenza >= now) {
        return "soft-blue";
      } else {
        return "soft-red";
      }
    }

    return "";
  }

  handleBolletta(bolletta: ContabilitaBollettaInfo) {
    return {
      ...bolletta,
      contratto: {
        ...bolletta.contratto,
        contrattiSuOspite: undefined,
      },
      fabbricato: bolletta.contratto.contrattiSuOspite[0].contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.fabbricato.nome,
      stanza: bolletta.contratto.contrattiSuOspite[0].contrattiSuOspiteSuPostoLetto[0].postoLetto.stanza.numeroStanza,
    };
  }

  async fetchBollette(): Promise<void> {
    if (!this.isDisabled) {
      try {
        this.loading = true;
        this.loadingBollette = true;
        this.totalBollette = await this.getContabilitaBolletteToExtractCount(this.queryParams);
        this.bollette = await this.getContabilitaBolletteToExtract(this.queryParams);
      } finally {
        this.loading = false;
        this.loadingBollette = false;
      }
    }
  }

  async exportTsv(): Promise<void> {
    if (!this.isDisabledExport) {
      try {
        this.loading = true;
        const bollette = await this.getContabilitaBolletteToExtract({ ...this.queryParams, page: undefined, pageSize: undefined });
        const handledBollette = bollette.map((bolletta) => this.handleBolletta(bolletta));
        const blob = createTsvBlob(this.exportHeaders, handledBollette);
        downloadBlob(blob, "contabilita_bollette_estrazione.tsv");
      } catch (error) {
        this.$store.dispatch(ActionTypes.ALERT, {
          message: `Errore nell'esporatare le bollette in TSV`,
          alertType: AlertType.ERRORS_QUEUE,
        });
      } finally {
        this.loading = false;
      }
    }
  }

  async exportXlsx(): Promise<void> {
    if (!this.isDisabledExport) {
      try {
        this.loading = true;
        const bollette = await this.getContabilitaBolletteToExtract({ ...this.queryParams, page: undefined, pageSize: undefined });
        const handledBollette = bollette.map((bolletta) => this.handleBolletta(bolletta));
        const blob = await createXlsxBlob("Bollette contabilità", this.exportHeaders, handledBollette);
        downloadBlob(blob, "contabilita_bollette_estrazione.xlsx");
      } catch (error) {
        this.$store.dispatch(ActionTypes.ALERT, {
          message: `Errore nell'esporatare le bollette in XLSX`,
          alertType: AlertType.ERRORS_QUEUE,
        });
      } finally {
        this.loading = false;
      }
    }
  }

  /* WATCH */

  @Watch("formValue", { deep: true })
  watchFormValue() {
    this.bollette = [];
  }

  @Watch("tableOptions", { deep: true })
  watchTableOptions() {
    this.fetchBollette();
  }

  /* LIFE CYCE */

  async mounted() {
    try {
      this.loadingTipiOspite = true;
      const tipiOspite = await this.getTipiOspite();
      this.tipiOspite = this.parseTipiOspite(tipiOspite);
    } finally {
      this.loadingTipiOspite = false;
    }
  }
}
