

























































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Fabbricato, FabbricatiCreateBody, FabbricatiReplaceBody, TipoFabbricato, Portineria } from "operatn-api-client";

import { AlertType } from "@/store";
import ResourceManagerMixin from "@/mixins/ResourceManagerMixin";
import FabbricatoHandlerMixin from "@/mixins/handlers/FabbricatoHandlerMixin";
import TipoFabbricatoHandlerMixin from "@/mixins/handlers/TipoFabbricatoHandlerMixin";
import PortineriaHandlerMixin from "@/mixins/handlers/PortineriaHandlerMixin";

import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnBaseResourceManager, { Column, Actions } from "@/components/gears/bases/OperatnBaseResourceManager.vue";
import OperatnFabbricatoForm from "@/components/gears/forms/OperatnFabbricatoForm.vue";
import { GroupHeaders } from "@/components/gears/bases/OperatnBaseTable.vue";

@Component({
  components: {
    OperatnActionDialog,
    OperatnBaseResourceManager,
    OperatnFabbricatoForm,
  },
})
export default class Fabbricati extends Mixins<
  ResourceManagerMixin<Fabbricato, FabbricatiCreateBody, FabbricatiReplaceBody, number> & FabbricatoHandlerMixin & TipoFabbricatoHandlerMixin & PortineriaHandlerMixin
>(ResourceManagerMixin, FabbricatoHandlerMixin, TipoFabbricatoHandlerMixin, PortineriaHandlerMixin) {
  /* PROPS */

  @Prop({ type: Boolean, required: true })
  isRoot!: boolean;

  /* DATA */

  protected askDeleteText = "Sei sicuro di voler eliminare questo fabbricato?";
  protected askDeleteMultipleText = "Sei sicuro di voler eliminare i fabbricati selezionati?";

  private tipiFabbricato: TipoFabbricato[] = [];
  private portinerie: Portineria[] = [];

  /* GETTERS AND SETTERS */

  get columns(): Column<Fabbricato>[] {
    return [
      {
        text: "ID",
        value: "id",
        groupable: false,
        editable: false,
      },
      {
        text: "Codice",
        value: "codice",
        groupable: false,

        editable: true,
        onEditCancel: () => this.sprepareUpdateBody(),
        onEditClose: () => {},
        onEditSave: () => this.updateValue(),
        onEditOpen: (item) => {
          this.prepareUpdateBody(item);
        },
        editInput: {
          type: "text",
          label: "Modifica",
          hint: "Premi invio per salvare",
          clearable: true,
          counter: true,
          rules: [this.$validator.requiredText("Codice"), this.$validator.unique(this.fabbricatiCodici)],
        },
      },
      {
        text: "Nome",
        value: "nome",
        groupable: false,

        editable: true,
        onEditCancel: () => this.sprepareUpdateBody(),
        onEditClose: () => {},
        onEditSave: () => this.updateValue(),
        onEditOpen: (item) => {
          this.prepareUpdateBody(item);
        },
        editInput: {
          type: "text",
          label: "Modifica",
          hint: "Premi invio per salvare",
          clearable: true,
          counter: true,
          rules: [this.$validator.requiredText("Nome"), this.$validator.unique(this.fabbricatiNomi)],
        },
      },
      {
        text: "Tipo fabbricato",
        value: "idTipoFabbricato",
        groupable: true,

        editable: true,
        onEditCancel: () => this.sprepareUpdateBody(),
        onEditClose: () => {},
        onEditSave: () => this.updateValue(),
        onEditOpen: (item) => {
          this.prepareUpdateBody(item);
        },
        editInput: {
          type: "select",
          label: "Modifica",
          hint: "Premi invio per salvare",
          items: this.tipiFabbricato,
          itemText: "tipoFabbricato",
          itemValue: "id",
          rules: [this.$validator.requiredText("Tipo fabbricato")],
        },

        itemTextHandler: (id) => this.tipiFabbricato.find((v) => v.id === id)?.tipoFabbricato ?? "NON TROVATO",
      },
      {
        text: "Provincia",
        value: "provincia",
        groupable: false,
      },
      {
        text: "Comune",
        value: "comune",
        groupable: true,
      },
      {
        text: "Lascia passare",
        value: "lasciapassare",
        groupable: true,

        editable: true,
        onEditCancel: () => this.sprepareUpdateBody(),
        onEditClose: () => {},
        onEditSave: () => this.updateValue(),
        onEditOpen: (item) => {
          this.prepareUpdateBody(item);
        },
        editInput: {
          type: "select",
          label: "Modifica",
          hint: "Premi invio per salvare",
          items: this.lasciaPassare,
          itemText: "label",
          itemValue: "lasciapassare",
          rules: [this.$validator.requiredText("Lascia passare")],
        },
        itemTextHandler: (lasciapassare) => this.lasciaPassare.find((v) => v.lasciapassare === lasciapassare)?.label ?? "NON TROVATO",
      },
      {
        text: "Portineria",
        value: "idPortineria",
        groupable: true,

        editable: true,
        onEditCancel: () => this.sprepareUpdateBody(),
        onEditClose: () => {},
        onEditSave: () => this.updateValue(),
        onEditOpen: (item) => {
          this.prepareUpdateBody(item);
        },
        editInput: {
          type: "select",
          label: "Modifica",
          hint: "Premi invio per salvare",
          items: this.portinerie,
          itemText: "nome",
          itemValue: "id",
          rules: [this.$validator.requiredText("Portineria")],
        },

        itemTextHandler: (id) => this.portinerie.find((v) => v.id === id)?.nome ?? "NON TROVATA",
      },
    ];
  }

  get exportedHeaders() {
    return this.columns ? this.getExportHeadersFromCols(this.columns) : {};
  }

  get actions(): Actions<Fabbricato> {
    return {
      onEdit: (item) => this.openEdit(item),
      onDelete: this.isRoot ? (item) => this.askDelete(item) : undefined,
      onView: (item) => `fabbricati/${item.id}`,
    };
  }

  get groupHeaders(): GroupHeaders {
    return {
      keyHandler: (key) => {
        switch (key) {
          case "idTipoFabbricato":
            return "TIPO FABBRICATO";
          case "idPortineria":
            return "PORTINERIA";
          default:
            return key;
        }
      },
      valueHandler: (value, key) => {
        switch (key) {
          case "idTipoFabbricato":
            return this.tipiFabbricato.find((v) => v.id === +value)?.tipoFabbricato ?? "NON TROVATO";
          case "idPortineria":
            return this.portinerie.find((v) => v.id === +value)?.nome ?? "NON TROVATO";
          default:
            return value;
        }
      },
    };
  }

  get fabbricatiCodici(): string[] {
    return this.getFabbricatiCodici(this.values, this.backupValue);
  }

  get fabbricatiNomi(): string[] {
    return this.getFabbricatiNomi(this.values, this.backupValue);
  }

  get lasciaPassare() {
    return this.$store.state.lasciaPassare;
  }

  /* METHODS */

  getIdFromValue(value: Fabbricato): number {
    return value.id;
  }

  async deleteHandler(id: number, isMultiple: boolean): Promise<void> {
    await this.deleteFabbricato(id, isMultiple ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  async createHandler(value: FabbricatiCreateBody): Promise<number> {
    return this.createFabbricato(value);
  }

  async updateHandler(id: number, value: FabbricatiReplaceBody, isTableEdit: boolean): Promise<void> {
    await this.updateFabbricato(id, value, isTableEdit ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  updateBodyFromValue(value: Fabbricato): FabbricatiReplaceBody {
    return {
      codice: value.codice,
      nome: value.nome,
      idTipoFabbricato: value.idTipoFabbricato,
      provincia: value.provincia,
      comune: value.comune,
      cap: value.cap,
      indirizzo: value.indirizzo,
      nCivico: value.nCivico,
      lasciapassare: value.lasciapassare,
      idPortineria: value.idPortineria,
    };
  }
  tupleValueFromCreateBody(id: number, body: FabbricatiCreateBody): Fabbricato {
    return {
      id,
      codice: body.codice,
      nome: body.nome,
      idTipoFabbricato: body.idTipoFabbricato,
      provincia: body.provincia,
      comune: body.comune,
      cap: body.cap,
      indirizzo: body.indirizzo,
      nCivico: body.nCivico,
      lasciapassare: body.lasciapassare,
      idPortineria: body.idPortineria,
      dataCreazione: this.backupValue?.dataCreazione ?? new Date(),
      eliminato: null,
    };
  }
  tupleValueFromUpdateBody(id: number, body: FabbricatiReplaceBody): Fabbricato {
    return {
      id,
      codice: body.codice,
      nome: body.nome,
      idTipoFabbricato: body.idTipoFabbricato,
      provincia: body.provincia,
      comune: body.comune,
      cap: body.cap,
      indirizzo: body.indirizzo,
      nCivico: body.nCivico,
      lasciapassare: body.lasciapassare,
      idPortineria: body.idPortineria,
      dataCreazione: this.backupValue?.dataCreazione ?? new Date(),
      eliminato: null,
    };
  }

  /* LIFE CYCLE */

  async mounted() {
    this.values = await this.getFabbricati();
    this.tipiFabbricato = await this.getTipiFabbricato();
    this.portinerie = await this.getPortinerie();
  }
}
