























import { Component, Vue } from "vue-property-decorator";

@Component
export default class RootContrattiView extends Vue {
  /* DATA */
  private activeTab = "";
  private tabs = [
    {
      path: "provvisori",
      icon: "mdi-tooltip-edit-outline",
      label: "Provvisori",
    },
    {
      path: "da-firmare",
      icon: "mdi-clipboard-edit",
      label: "Da firmare",
    },

    {
      path: "da-visionare",
      icon: "mdi-eye-check-outline",
      label: "Da visionare",
    },
    {
      path: "futuri",
      icon: "mdi-page-next-outline",
      label: "Futuri",
    },
    {
      path: "attivi",
      icon: "mdi-alarm-check",
      label: "Attivi",
    },
    {
      path: "terminati",
      icon: "mdi-archive",
      label: "Terminati",
    },
  ];
}
