




import { Component, Prop, Vue } from "vue-property-decorator";
import StanzaId from "@/components/pages/fabbricati/stanza/StanzaId.vue";

@Component({
  components: {
    StanzaId,
  },
})
export default class RootFabbricatiCodiceStanzeIdView extends Vue {
  /* PROPS */

  @Prop({ type: String, required: true })
  fid!: string;

  @Prop({ type: String, required: true })
  sid!: string;
}
