




import { Component, Prop, Vue } from "vue-property-decorator";
import OspitiId from "@/components/pages/ospiti/ospite/OspitiId.vue";

@Component({
  components: {
    OspitiId,
  },
})
export default class RootOspitiIdView extends Vue {
  /* PROPS */

  @Prop({ type: String, required: true })
  id!: string;
}
