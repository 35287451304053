














































import { Component, Mixins, Prop } from "vue-property-decorator";
import { TipoDocumentoIdentita, TipiDocumentoIdentitaCreateBody, TipiDocumentoIdentitaReplaceBody } from "operatn-api-client";

import { AlertType } from "@/store";
import ResourceManagerMixin from "@/mixins/ResourceManagerMixin";
import TipoDocumentoIdentitaHandlerMixin from "@/mixins/handlers/TipoDocumentoIdentitaHandlerMixin";

import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnBaseResourceManager, { Column, Actions } from "@/components/gears/bases/OperatnBaseResourceManager.vue";
import OperatnTipoDocumentoIdentitaForm from "@/components/gears/forms/OperatnTipoDocumentoIdentitaForm.vue";

@Component({
  components: {
    OperatnActionDialog,
    OperatnBaseResourceManager,
    OperatnTipoDocumentoIdentitaForm,
  },
})
export default class TipiDocumentoIdentita extends Mixins<
  ResourceManagerMixin<TipoDocumentoIdentita, TipiDocumentoIdentitaCreateBody, TipiDocumentoIdentitaReplaceBody, number> & TipoDocumentoIdentitaHandlerMixin
>(ResourceManagerMixin, TipoDocumentoIdentitaHandlerMixin) {
  /* PROPS */

  @Prop({ type: Boolean, required: true })
  isRoot!: boolean;

  /* DATA */

  protected askDeleteText = "Sei sicuro di voler eliminare questo tipo documento identita?";
  protected askDeleteMultipleText = "Sei sicuro di voler eliminare i tipi documento identita selezionati?";

  /* GETTERS AND SETTERS */

  get columns(): Column<TipoDocumentoIdentita>[] {
    return [
      {
        text: "ID",
        value: "id",
        groupable: false,
        editable: false,
      },
      {
        text: "Tipo documento identita",
        value: "tipoDocumentoIdentita",
        groupable: false,

        editable: true,
        onEditCancel: () => this.sprepareUpdateBody(),
        onEditClose: () => {},
        onEditSave: () => this.updateValue(),
        onEditOpen: (item) => {
          this.prepareUpdateBody(item);
        },
        editInput: {
          type: "text",
          label: "Modifica",
          hint: "Premi invio per salvare",
          counter: true,
          rules: [this.$validator.requiredText("Tipo documento identita"), this.$validator.unique(this.tipiDocumentoIdentitaValues)],
        },
      },
    ];
  }

  get tipiDocumentoIdentitaValues(): string[] {
    return this.getTipiDocumentoIdentitaValues(this.values, this.backupValue);
  }

  get actions(): Actions<TipoDocumentoIdentita> {
    return {
      onEdit: (item) => this.openEdit(item),
      onDelete: this.isRoot ? ((item) => this.askDelete(item)) : undefined,
    };
  }

  /* METHODS */

  getIdFromValue(value: TipoDocumentoIdentita): number {
    return value.id;
  }

  async deleteHandler(id: number, isMultiple: boolean): Promise<void> {
    await this.deleteTipoDocumentoIdentita(id, isMultiple ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  async createHandler(value: TipiDocumentoIdentitaCreateBody): Promise<number> {
    return this.createTipoDocumentoIdentita(value);
  }

  async updateHandler(id: number, value: TipiDocumentoIdentitaReplaceBody, isTableEdit: boolean): Promise<void> {
    await this.updateTipoDocumentoIdentita(id, value, isTableEdit ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  updateBodyFromValue(value: TipoDocumentoIdentita): TipiDocumentoIdentitaReplaceBody {
    return {
      tipoDocumentoIdentita: value.tipoDocumentoIdentita,
    };
  }
  tupleValueFromCreateBody(id: number, body: TipiDocumentoIdentitaCreateBody): TipoDocumentoIdentita {
    return {
      id,
      tipoDocumentoIdentita: body.tipoDocumentoIdentita,
    };
  }
  tupleValueFromUpdateBody(id: number, body: TipiDocumentoIdentitaReplaceBody): TipoDocumentoIdentita {
    return {
      id,
      tipoDocumentoIdentita: body.tipoDocumentoIdentita,
    };
  }

  /* LIFE CYCLE */

  async mounted() {
    this.values = await this.getTipiDocumentoIdentita();
  }
}
