





































import { Component, Mixins, Prop } from "vue-property-decorator";
import { PresidentiReturned, PresidentiCreateBody, PresidentiUpdateBody, LuogoDiNascita, Residenza } from "operatn-api-client";

import { AlertType } from "@/store";

import ResourceManagerMixin from "@/mixins/ResourceManagerMixin";
import PresidenteHandlerMixin from "@/mixins/handlers/PresidenteHandlerMixin";

import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnBaseResourceManager, { Column, Actions } from "@/components/gears/bases/OperatnBaseResourceManager.vue";
import OperatnPresidenteForm from "@/components/gears/forms/persona/OperatnPresidenteForm.vue";

@Component({
  components: {
    OperatnActionDialog,
    OperatnBaseResourceManager,
    OperatnPresidenteForm,
  },
})
export default class Presidenti extends Mixins<ResourceManagerMixin<PresidentiReturned, PresidentiCreateBody, PresidentiUpdateBody, number> & PresidenteHandlerMixin>(
  ResourceManagerMixin,
  PresidenteHandlerMixin
) {
  /* PROPS */

  @Prop({ type: Boolean, required: true })
  isRoot!: boolean;

  /* DATA */

  protected askDeleteText = "Sei sicuro di voler eliminare questo presidente?";
  protected askDeleteMultipleText = "Sei sicuro di voler eliminare i presidenti selezionati?";

  private loading = false;
  private firma: File | string | null = null;

  /* GETTERS AND SETTERS */

  get columns(): Column<PresidentiReturned>[] {
    return [
      {
        text: "ID",
        value: "id",
        groupable: false,
        editable: false,
      },
      {
        text: "Nome",
        value: "nome",
        groupable: false,
        editable: false,
      },
      {
        text: "Cognome",
        value: "cognome",
        groupable: false,
        editable: false,
      },
      {
        text: "Sesso",
        value: "sesso",
        groupable: false,
        editable: false,
      },
      {
        text: "Data di nascita",
        value: "dataDiNascita",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value: Date) => value.toLocaleDateString(),
      },
      {
        text: "Inizio mandato",
        value: "dataInizioMandato",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value: Date) => value.toLocaleDateString(),
      },
      {
        text: "Fine mandato",
        value: "dataFineMandato",
        groupable: false,
        sort: (x: Date, y: Date) => +x - +y,

        editable: false,
        itemTextHandler: (value: Date) => value.toLocaleDateString(),
      },
    ];
  }

  get actions(): Actions<PresidentiReturned> {
    return {
      onEdit: (item) => this.openEdit(item),
      onDelete: this.isRoot ? (item) => this.askDelete(item) : undefined,
      others: [
        {
          icon: "mdi-fingerprint",
          color: "warning",
          title: "Scarica firma",
          action: (item) => this.downloadFirma(item),
        },
        {
          icon: "mdi-eye",
          color: "success",
          title: "Visualizza dettagli",
          action: (item) => {
            this.$router.push(`./presidenti/${item.id}`);
          },
        },
      ],
    };
  }

  /* METHODS */

  getIdFromValue(value: PresidentiReturned): number {
    return value.id;
  }

  async deleteHandler(id: number, isMultiple: boolean): Promise<void> {
    await this.deletePresidente(id, isMultiple ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  async createHandler(value: PresidentiCreateBody): Promise<{ id: number; details: { firma: string | null } }> {
    const id = await this.createPresidente(value);
    let firma: string | null = null;

    if (this.firma instanceof File) {
      const formData = new FormData();
      formData.append("firma", this.firma);
      firma = await this.uploadPresidenteFirma(id, formData, AlertType.ERROR_ALERT);
    }

    this.firma = null;

    return { id, details: { firma } };
  }

  async updateHandler(id: number, value: PresidentiUpdateBody, isTableEdit: boolean): Promise<{ firma: string | null }> {
    await this.updatePresidente(id, value, isTableEdit ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);

    let firma: string | null = null;

    if (this.firma instanceof File) {
      const formData = new FormData();
      formData.append("firma", this.firma);
      firma = await this.uploadPresidenteFirma(id, formData, AlertType.ERROR_ALERT);
    } else {
      firma = this.firma;
    }

    this.firma = null;

    return { firma };
  }

  updateBodyFromValue(value: PresidentiReturned): PresidentiUpdateBody {
    const luogoDiNascita = value.luogoDiNascita as LuogoDiNascita;
    delete (luogoDiNascita as any).id;

    const residenza = value.residenza as Residenza;
    delete (residenza as any).id;

    const domicili = value.domicili
      ? value.domicili.map((el) => {
          delete (el as any).id;
          delete (el as any).idPersona;
          return el;
        })
      : [];

    this.firma = value.firma ? this.$stored.getPath(value.firma) : null;

    return {
      nome: value.nome,
      cognome: value.cognome,
      codiceFiscale: value.codiceFiscale,
      sesso: value.sesso,
      dataDiNascita: value.dataDiNascita,
      dataInizioMandato: value.dataInizioMandato,
      dataFineMandato: value.dataFineMandato,
      luogoDiNascita: luogoDiNascita,
      residenza: residenza,
      domicili: domicili,
    };
  }
  tupleValueFromCreateBody(id: number, body: PresidentiCreateBody, details: { firma: string | null }): PresidentiReturned {
    return {
      id,
      nome: body.nome,
      cognome: body.cognome,
      codiceFiscale: body.codiceFiscale ?? null,
      sesso: body.sesso,
      dataDiNascita: body.dataDiNascita,
      luogoDiNascita: body.luogoDiNascita as any,
      residenza: body.residenza as any,
      domicili: body.domicili ?? ([] as any),
      dataInizioMandato: body.dataInizioMandato,
      dataFineMandato: body.dataFineMandato,
      firma: details.firma,
      dataCreazione: new Date(),
      eliminato: null,
    };
  }
  tupleValueFromUpdateBody(id: number, body: PresidentiUpdateBody, backupValue: PresidentiReturned, details: { firma: string | null }): PresidentiReturned {
    return {
      id,
      nome: body.nome ?? "",
      cognome: body.cognome ?? "",
      codiceFiscale: body.codiceFiscale ?? null,
      sesso: body.sesso ?? "",
      dataDiNascita: body.dataDiNascita as Date,
      luogoDiNascita: body.luogoDiNascita as any,
      residenza: body.residenza as any,
      domicili: body.domicili ?? ([] as any),
      dataInizioMandato: body.dataInizioMandato as Date,
      dataFineMandato: body.dataFineMandato as Date,
      firma: details.firma,
      dataCreazione: backupValue.dataCreazione,
      eliminato: backupValue.eliminato,
    };
  }

  async downloadFirma(item: PresidentiReturned): Promise<void> {
    await this.$stored.download(item.firma as string, `firma_presidente_${item.id}`);
  }

  /* LIFE CYCLE */

  async mounted() {
    try {
      this.loading = true;
      this.values = await this.getPresidenti({
        persona: {
          luogoDiNascita: true,
          residenza: true,
          domicili: true,
        },
      });
    } finally {
      this.loading = false;
    }
  }
}
