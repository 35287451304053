


































































































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Comune, FabbricatiCreateBody, FabbricatiReplaceBody, Portineria, Provincia, TipoFabbricato } from "operatn-api-client";

import { ActionTypes, AlertType } from "@/store";
import TipoFabbricatoHandlerMixin from "@/mixins/handlers/TipoFabbricatoHandlerMixin";
import PortineriaHandlerMixin from "@/mixins/handlers/PortineriaHandlerMixin";

@Component({
  model: {
    prop: "value",
    event: "save",
  },
})
export default class OperatnFabbricatoForm extends Mixins(TipoFabbricatoHandlerMixin, TipoFabbricatoHandlerMixin, PortineriaHandlerMixin) {
  /* PROPS */

  @Prop({ type: Object, default: null })
  value!: Partial<FabbricatiCreateBody> | Partial<FabbricatiReplaceBody>;

  @Prop({ type: Boolean, default: false })
  formValid!: boolean;

  @Prop({ type: Array, default: () => [] })
  fabbricatiCodici!: string[];

  @Prop({ type: Array, default: () => [] })
  fabbricatiNomi!: string[];

  /* DATA */

  private tipiFabbricato: TipoFabbricato[] = [];
  private portinerie: Portineria[] = [];
  private province: Provincia[] = [];
  private comuni: Comune[] = [];
  private isLoading = {
    province: false,
    comuni: false,
  };

  /* GETTERS AND SETTERS */

  get internalValue() {
    return this.value;
  }
  set internalValue(value) {
    this.$emit("save", value);
  }

  get internalFormValid() {
    return this.formValid;
  }
  set internalFormValid(value) {
    this.$emit("update:formValid", value);
  }

  get lasciaPassare() {
    return this.$store.state.lasciaPassare;
  }

  /* METHODS */

  getEmptyValue(): Partial<FabbricatiCreateBody> | Partial<FabbricatiReplaceBody> {
    return {
      codice: undefined,
      nome: undefined,
      provincia: undefined,
      comune: undefined,
      cap: undefined,
      indirizzo: undefined,
      nCivico: undefined,
      idTipoFabbricato: undefined,
    };
  }

  async fetchProvince(): Promise<void> {
    try {
      this.isLoading.province = true;
      this.province = await this.$api.province.getAll();
    } catch (error) {
      this.province = [];
      this.$store.dispatch(ActionTypes.ALERT, { message: "Errore nel caricare le province", alertType: AlertType.ERRORS_QUEUE });
    } finally {
      this.isLoading.province = false;
    }
  }

  async fetchComuni(provincia?: string): Promise<void> {
    if (provincia) {
      try {
        this.isLoading.comuni = true;
        this.comuni = (await this.$api.comuni.getAll()).filter((c) => c.siglaProvincia === provincia);
      } catch (error) {
        this.comuni = [];
        this.$store.dispatch(ActionTypes.ALERT, { message: "Errore nel caricare i comuni", alertType: AlertType.ERRORS_QUEUE });
      } finally {
        this.isLoading.comuni = false;
      }
    } else {
      this.comuni = [];
    }
  }

  /* WATCH */

  @Watch("value", { deep: true, immediate: true })
  watchValue() {
    if (this.value === null) {
      this.internalValue = this.getEmptyValue();
    }
  }

  @Watch("value.provincia", { deep: true, immediate: true })
  async watchValueProvincia(provincia) {
    await this.fetchComuni(provincia);
  }

  /* LIFE CYCLE */

  async created() {
    this.tipiFabbricato = await this.getTipiFabbricato();
    this.portinerie = await this.getPortinerie();
    await this.fetchProvince();
    // TODO: add province mixin handler
    try {
      await this.fetchComuni(this.value.provincia);
    } catch (error) {}
  }
}
